.footer {
  background-color: #7CB9E8;
  padding: 0.5rem 0; /* Adjusted padding to make the footer shorter */
  text-align: center;
  color: white;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-left, .footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.footer-links a.footer-link {
  color: white;
  text-decoration: none;
}

.footer-links a.footer-link:hover {
  text-decoration: underline;
}

.vk-logo {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  margin-left: 30px; /* Add margin to move the logo to the right */
}
