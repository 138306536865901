/* Events.css */

/* Container for events */
.events-container {
  padding: 20px;
}

/* List of events */
.events-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Individual event item */
.event-item {
  border: 1px solid #ddd; /* Border around event item */
  border-radius: 8px; /* Rounded corners */
  padding: 15px;
  margin-bottom: 10px;
  background-color: #fff; /* Background color for event item */
  cursor: pointer; /* Pointer cursor on hover */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions */
}

/* Hover effect for event item */
.event-item:hover {
  transform: scale(1.02); /* Slightly scale up the item */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
}

/* Event images inside the popup */
.event-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

/* Style for each image */
.event-image {
  width: 100px; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  object-fit: cover; /* Ensure images cover the container without distortion */
  border-radius: 8px; /* Rounded corners for images */
}

/* Popup container */
.event-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Popup content */
.event-popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto; /* Scrollable if content overflows */
  position: relative; /* Needed for positioning the close button */
}

/* Close button style */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
}

/* Register button style */
.register-btn {
  background-color: #28a745; /* Green background color */
  color: #fff; /* White text color */
  border: 2px solid #28a745; /* Border color */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.2s ease, border-color 0.2s ease; /* Smooth transitions */
}

/* Register button hover effect */
.register-btn:hover {
  background-color: #218838; /* Darker green on hover */
  border-color: #218838; /* Darker green border on hover */
}