/* LocationDetails.css */

.location-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 800px;
  margin: auto;
}

.location-info {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.location-info h2 {
  margin-bottom: 10px;
}

.location-info p {
  margin-bottom: 10px;
}

.location-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  max-height: 200px;
  border-radius: 8px;
  overflow: hidden;
  margin: auto;
}

.location-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.location-buttons {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.events-section {
  width: 100%;
  margin-top: 20px;
}

.events-tab-container {
  text-align: center;
  margin-bottom: 10px;
}

.events-tab {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.tab {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  position: relative;
}

.tab.active {
  font-weight: bold;
}

.tab.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
}

.events-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
