/* App.css or a global CSS file */
html, body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header select {
  margin-top: 60px; /* Adjust based on the height of the Navbar */
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 650px;
  padding-top: 20px;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: transform 0.5s ease;
}

.background-image:hover {
  transform: scale(1.05);
}

.info {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%; /* Adjust as needed */
  margin: 20px 0; /* Adjust as needed */
}

.info-item {
  flex: 1 1 45%;
  margin: 10px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.info-item:hover {
  transform: scale(1.05);
}

.toggle-button {
  margin-top: 20px; /* Adjust as needed */
  padding: 20px 20px;
  background-color: #7CB9E8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s, transform 0.3s;
}

.toggle-button:hover {
  background-color: #009acd;
  transform: scale(1.1);
}

.modal-overlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; 
  animation: fadeIn 0.5s;
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  width: 30%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 11;
  animation: slideIn 0.5s;
}

.close-button {
  position: absolute;
  top: -3px;
  right: 0px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 1; 
}

.main {
  padding: 80px 20px 20px 20px;
  text-align: center;
}

.cta-button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s, transform 0.3s;
}

.cta-button:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

.locations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  margin-top: 20px;
}

footer {
  background-color: #007BFF;
  color: white;
  text-align: center;
  padding: 10px 0;
  margin-top: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.background-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #7CB9E8; /* Add a background color if needed */
  padding: 40px; /* Adjust padding as needed */
}

.background-section .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.background-section .text {
  flex: 1;
  margin-left: 20px;
  color: black;
  font-size: 1.5em;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.background-section .text h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.background-section .text p {
  background-color:#7CB9E8;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}


.sport-image {
  width: 650px; /* Adjust width */
  height: 400px; /* Adjust height */
  border-radius: 8px;
}

@media (max-width: 768px) {
  .background-section .content {
    flex-direction: column; /* Change to column on mobile */
  }

  .background-section .image {
    text-align: center;
    margin-bottom: 20px; /* Add margin to separate image from text */
  }

  .background-section .text {
    text-align: center;
    margin-right: 0; /* Remove right margin */
  }
}