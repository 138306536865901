/* Ensure the container takes up the full height and centers its content */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
  box-sizing: border-box; /* Ensures padding is included in the total width/height */
}

/* Info section styling */
.infor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 1rem;
}

.infor-item {
  flex-grow: 1;
  margin-right: 1rem;
}

.toggle-button {
  padding: 10px 20px;
  background-color: #7cb9e8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
  align-self: flex-start; /* Ensure the button aligns with the start of the section */
}

.toggle-button:hover {
  background-color: #0056b3;
}

/* Center the header section */
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Full width to ensure centering */
  max-width: 1200px; /* Maximum width for content */
  margin: 0 auto; /* Center align */
  text-align: center; /* Center the text content */
}

/* Style for the dropdown menu */
.dropdown {
  outline: none;
  padding: 0.5rem;
  background-color: #ffffff; /* Background color */
  border: 1px solid #d1d5db; /* Border color */
  border-radius: 9999px; /* Rounded corners */
  margin-bottom: 1rem; /* Space between dropdown and search bar */
  margin-top: 1rem;
  width: 250px; /* Width of dropdown */
}

/* Style for the search bar container */
.search-bar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

/* Style for the search bar */
.search-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the search bar */
  width: 100%;
}

.search-input {
  width: 100%; /* Use the full width of the container */
  max-width: 800px; /* Maximum width for the search bar */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.search-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.search-btn i {
  font-size: 20px;
  color: #333;
}

.autocomplete-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  border: 1px solid #ddd;
  background-color: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
}

.autocomplete-suggestions li {
  padding: 10px;
  cursor: pointer;
}

.autocomplete-suggestions li:hover {
  background-color: #f0f0f0;
}

/* Map container style */
.map-container {
  width: 100%;
  max-width: 1200px; /* Maximum width for the map */
  height: 60vh; /* Adjusted height to make the map smaller */
  margin-top: 1rem; /* Space between map and other elements */
  margin-bottom: 2rem; /* Increased space between map and footer */
}

/* Modal overlay and content styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  animation: fadeIn 0.5s;
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  width: 30%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 11;
  animation: slideIn 0.5s;
}

.close-button {
  position: absolute;
  top: -3px;
  right: 0px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
}

.separator {
  width: 100%;
  height: 2px;
  background-color: black;
  margin: 20px 0; /* Adjust as needed for spacing */
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
