.alert {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    padding: 20px;
    border-radius: 5px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}
