/* SearchBar.css */
.search-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the search bar */
  width: 170%;
}

.search-input {
  width: 100%; /* Use the full width of the container */
  max-width: 800px; /* Maximum width for the search bar */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.search-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.search-btn i {
  font-size: 20px;
  color: #333;
}

.autocomplete-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  border: 1px solid #ddd;
  background-color: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
}

.autocomplete-suggestions li {
  padding: 10px;
  cursor: pointer;
}

.autocomplete-suggestions li:hover {
  background-color: #f0f0f0;
}
