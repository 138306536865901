.add-court-container {
  background-color: #f0f8ff; /* Light blue background */
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-top: 80px; /* Adjust this value if needed */
  position: relative;
  z-index: 10; /* Ensure it is below Navbar if needed */
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.input-field,
.textarea-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.textarea-field {
  height: 100px;
}


.dropzone {
  border: 2px dashed #00bfff; /* Light blue border */
  padding: 20px;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #e0f7ff; /* Light blue background for dropzone */
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.reset-button,
.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.reset-button {
  background-color: #d3d3d3; /* Light grey background */
  color: #000;
}

.submit-button {
  background-color: #00bfff; /* Light blue background */
  color: #fff;
}

.reset-button:hover,
.submit-button:hover {
  opacity: 0.8;
}



.alert-danger{
  color: red;
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
}

.alert-success{
  color: green;
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
}
